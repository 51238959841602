import React from 'react';
import Icon from '../icon';

import closeL from '../svg/close.l.sprite.svg';

const IconCloseL = (props) => {
	return <Icon {...props} sprite={closeL} />;
};

IconCloseL.propTypes = Icon.propTypes;
IconCloseL.defaultProps = Icon.defaultProps;

export default IconCloseL;
