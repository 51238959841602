import React from 'react';
import PropTypes from 'prop-types';

import HeaderCPlain from '@src/components/Header/connectors/plain';
import HeaderCListing from '@src/components/Header/connectors/listing';
import SeoBlockCDefault from '@src/components/SeoBlock/connectors/default';
import FooterCDefault from '@src/components/Footer/connectors/default';

import LayoutBase from '..';

const LayoutBaseCDefault = (props) => {
	const { children, headerType, ...rest } = props;
	let Header;

	switch (headerType) {
		case 'plain':
			Header = HeaderCPlain;
			break;
		default:
			Header = HeaderCListing;
	}

	const headerProps = React.useMemo(() => {
		return {
			adaptive: 'default',
		};
	}, []);

	return (
		<>
			<LayoutBase
				Header={Header}
				Footer={FooterCDefault}
				headerProps={headerProps}
				rootId="appRoot"
				SeoBlock={SeoBlockCDefault}
				{...rest}
			>
				{children}
			</LayoutBase>
		</>
	);
};

LayoutBaseCDefault.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	onClickMap: PropTypes.func,
	headerType: PropTypes.oneOf(['plain', 'listing']),
	isMobileNonStickyHeader: PropTypes.bool,
};

LayoutBaseCDefault.defaultProps = {
	children: undefined,
	onClickMap: undefined,
	headerType: 'listing',
	isMobileNonStickyHeader: false,
};

export default LayoutBaseCDefault;
