import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';

import { useActions } from '@src/containers/Session/hooks';
import { MODAL_IDS, ROUTE_PATHS } from '@src/containers/Session/constants';
import { getEvents } from '@src/containers/Filters/selectors/getEvents';
import { getCapacityItems } from '@src/containers/Filters/selectors/getCapacity';
import getFavourites from '@src/containers/Session/selectors/getFavourites';
import { getMainFiltersVisibility } from '@src/containers/Filters/selectors/getMainFiltersVisibility';
import { getCity } from '@src/containers/Session/selectors/getCity';
import useHandleManageFiltersClick from '@src/libs/hooks/useHandleManageFiltersClick';
import getSelectedFilters from '@src/containers/Filters/selectors/getSelectedFilters';
import { getSFUValues } from '@src/containers/Filters/selectors/getSFUValues';
import { ANALYTICS_GOAL, useReachGoal } from '@src/libs/hooks/useReachGoal';

import Header from '..';

const HeaderCListing = ({ isDesktopNonSticky, isMobileNonSticky, ...rest }) => {
	const { toggleModal } = useActions();
	const city = useSelector(getCity);
	const events = useSelector(getEvents);
	const capacityItems = useSelector(getCapacityItems);
	const favs = useSelector(getFavourites);
	const filters = useSelector(getSelectedFilters);

	const { reachGoal } = useReachGoal();
	const handleClickFav = React.useCallback(() => {
		reachGoal(ANALYTICS_GOAL.click_favorites_btn);
	}, [reachGoal]);

	const { handleManageFiltersClick } = useHandleManageFiltersClick();

	const handleClickFilterBtn = React.useCallback(() => {
		handleManageFiltersClick();
	}, [handleManageFiltersClick]);

	const favsQtty = favs?.spaces?.length || 0;
	const {
		spec: specId,
		capacity,
		date,
	} = useSelector((store) => store.Session.query);
	const sfu = useSelector(getSFUValues);

	let eventType;
	let placeholder;
	let guestQtty;
	let eventDate;

	if (city) {
		placeholder = t('Что планируете?');
	}
	const eventId = specId || sfu.spec;
	const targetEvent = eventId
		? events.find((e) => e.value === `${eventId}`)
		: null;

	if (targetEvent) {
		eventType = `${targetEvent.label} ${t('в')} ${city.name3}`;

		if (eventType && !guestQtty) {
			placeholder = t('Сколько гостей?');
		}
	}

	const capacitySFU = capacity || sfu.capacity;
	if (capacitySFU) {
		const targetCapacity = capacityItems.find(
			(i) => i.value === capacitySFU,
		);
		if (targetCapacity) {
			guestQtty = `${targetCapacity.label} ${t('гостей')}`;

			if (eventType && guestQtty && !eventDate) {
				placeholder = t('Когда?');
			}
		}
	}

	if (date) {
		eventDate = dayjs(date).format('DD.MM.YYYY');

		if (eventType && guestQtty && eventDate) {
			placeholder = null;
		}
	}

	const handleFilterClick = useCallback(() => {
		reachGoal(ANALYTICS_GOAL.click_search_panel);
		toggleModal({
			modalId: MODAL_IDS.FILTER_MODAL,
			isOpen: true,
		});
	}, [toggleModal, reachGoal]);

	const favLink = ROUTE_PATHS.SPACES_FAVOUR.replace('[town]', city?.domain);

	const isMainFiltersVisible = useSelector(getMainFiltersVisibility);

	return (
		<Header
			eventType={eventType}
			guestQtty={city ? guestQtty : null}
			eventDate={city ? eventDate : null}
			onFilterClick={handleFilterClick}
			isLinkOnLogo
			likesQtty={favsQtty}
			favourLink={favsQtty > 0 ? favLink : null}
			placeholder={placeholder}
			city={city?.name}
			isFiltersVisible={!isMainFiltersVisible}
			onClickFilterBtn={handleClickFilterBtn}
			filtersQtty={filters.length > 0 ? filters.length : undefined}
			isDesktopNonSticky={isDesktopNonSticky}
			isMobileNonSticky={isMobileNonSticky}
			onClickFav={handleClickFav}
			{...rest}
		/>
	);
};

export default HeaderCListing;

HeaderCListing.propTypes = {
	isDesktopNonSticky: PropTypes.bool,
	isMobileNonSticky: PropTypes.bool,
};

HeaderCListing.defaultProps = {
	isDesktopNonSticky: false,
	isMobileNonSticky: false,
};
