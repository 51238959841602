import { createSelector } from 'reselect';
import getRouterCtx from '@src/containers/Session/selectors/getRouterCtx';
import getFiltersList from './getFiltersList';
import { getSFUValues } from './getSFUValues';

const getSelectedFilters = createSelector(
	getFiltersList,
	getRouterCtx,
	getSFUValues,
	(filters, router, sfu) => {
		const { p, price, district_id } = { ...router.query, ...sfu };
		const result = [];
		if (p) {
			const splittedP = p.split(',');

			filters.forEach((f) => {
				if (f.checks?.data) {
					f.checks.data.forEach((check) => {
						if (splittedP.includes(check.value)) {
							result.push({
								name: f.key,
								title: check.title,
								value: check.value,
							});
						}
					});
				}
			});
		}
		if (district_id) {
			const splittedP = district_id.split(',');

			filters.forEach((f) => {
				if (f.checks?.data) {
					f.checks.data.forEach((check) => {
						if (splittedP.includes(check.value)) {
							result.push({
								name: f.key,
								title: check.title,
								value: check.value,
							});
						}
					});
				}
			});
		}
		if (price) {
			result.push({
				name: 'price',
				title: `${t('Стоимость')} ${price} ₽`,
				value: price,
			});
		}
		return result;
	},
);

export default getSelectedFilters;
