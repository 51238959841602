import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import IconCloseL from '@src/components/Icon/set/IconCloseL';
import HeaderLogo from '@src/components/HeaderLogo';
import Freezer from '@src/components/Freezer';
import { FloatingTree } from '@floating-ui/react-dom-interactions';

import css from './style.module.scss';

const Popup = (props) => {
	const {
		children,
		isOpen,
		isCentred,
		contentMix,
		innerMix,
		adaptive,
		state,
		isPlated,
		title,
		onClose,
		isWithHeader,
		isLoading,
		animation,
		additionalClass,
	} = props;

	if (!isOpen) return null;
	if (isLoading) return children;

	return (
		<div
			className={cn(css.block, {
				[css[`block_adapt_${adaptive}`]]: !!adaptive,
				[css[`block_state_${state}`]]: !!state,
				[css[`block_animation_${animation}`]]: !!animation,
				[css.block_overflow]: isLoading,
			})}
		>
			<Freezer
				freezeClass={cn(additionalClass, '--scroll-compensator')}
			/>
			<FloatingTree>
				<div
					className={cn(css.wrap, {
						[css.wrap_centred]: isCentred,
					})}
					data-popup-wrap
				>
					<div
						className={css.bg}
						tabIndex={0}
						role="button"
						aria-label="close"
						onClick={onClose}
					/>
					{!isLoading && (
						<div className={cn(css.content, contentMix)}>
							<div
								className={css.closer}
								tabIndex={0}
								role="button"
								aria-label="close"
								onClick={onClose}
							/>
							{isWithHeader && (
								<div className={cn(css.header)}>
									<HeaderLogo />
								</div>
							)}
							<div className={cn(css.inner, innerMix)}>
								{isPlated ? (
									<div className={css.plate}>
										{title && (
											<div className={css.title}>
												{title}
											</div>
										)}
										{children}
									</div>
								) : (
									children
								)}
								<a
									tabIndex={0}
									role="link"
									onClick={onClose}
									className={css.closeBtn}
								>
									<IconCloseL className={css.closeIcon} />
								</a>
							</div>
						</div>
					)}
					<div data-popup-portal />
				</div>
			</FloatingTree>
		</div>
	);
};

Popup.propTypes = {
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
	isOpen: PropTypes.bool,
	contentMix: PropTypes.string,
	innerMix: PropTypes.string,
	isCentred: PropTypes.bool,
	adaptive: PropTypes.oneOf([
		null,
		'default',
		'message',
		'headerForm',
		'filter',
		'fullscreen',
		'claim',
	]),
	state: PropTypes.oneOf(['fullscreen']),
	isPlated: PropTypes.bool,
	title: PropTypes.string,
	onClose: PropTypes.func,
	isWithHeader: PropTypes.bool,
	isLoading: PropTypes.bool,
	animation: PropTypes.oneOf([null, 'default']),
	additionalClass: PropTypes.string,
};

Popup.defaultProps = {
	children: undefined,
	contentMix: undefined,
	adaptive: null,
	innerMix: undefined,
	state: undefined,
	isCentred: false,
	isOpen: false,
	isPlated: false,
	title: undefined,
	onClose: undefined,
	isWithHeader: true,
	isLoading: false,
	animation: 'default',
	additionalClass: undefined,
};

export default Popup;
