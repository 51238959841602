import { useEffect } from 'react';

const useFreeze = ({ freezeClass, cb } = {}) => {
	useEffect(() => {
		const isAlreadyFreeze = document.body.classList.contains(freezeClass);
		if (!isAlreadyFreeze) {
			window.dispatchEvent(new Event('freeze'));
			document.documentElement.classList.add('htmlFixed');
			document.body.classList.add(...freezeClass.split(' '));
		}

		if (cb) cb();

		return () => {
			if (!isAlreadyFreeze) {
				window.dispatchEvent(new Event('unfreeze'));
				document.documentElement.classList.remove('htmlFixed');
				document.body.classList.remove(...freezeClass.split(' '));
			}
		};
	}, [freezeClass, cb]);
};

export default useFreeze;
