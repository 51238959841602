import React from 'react';
import Icon from '../icon';

import logo from '../svg/logo-big.sprite.svg';

const IconLogoBig = (props) => {
	return <Icon {...props} sprite={logo} />;
};

IconLogoBig.propTypes = Icon.propTypes;
IconLogoBig.defaultProps = Icon.defaultProps;

export default IconLogoBig;
