import React from 'react';
import { MODAL_IDS } from '@src/containers/Session/constants';
import SearchFormCDynamicDefault from '@src/components/SearchForm/connectors/dynamicDefault';

import PopupCDefault from '../connectors/default';

const PopupCHeaderSearch = () => {
	const [isLoading, setIsLoading] = React.useState(true);

	const handleMount = React.useCallback(() => {
		setIsLoading(false);
	}, []);

	return (
		<PopupCDefault
			modalId={MODAL_IDS.FILTER_MODAL}
			adaptive="headerForm"
			isWithHeader
			isLoading={isLoading}
			additionalClass="--header-search"
		>
			<SearchFormCDynamicDefault onMount={handleMount} />
		</PopupCDefault>
	);
};

export default PopupCHeaderSearch;
