import React from 'react';

import { MODAL_IDS } from '@src/containers/Session/constants';
import { useActions } from '@src/containers/Session/hooks';
import { ANALYTICS_GOAL, useReachGoal } from './useReachGoal';

const useHandleManageFiltersClick = () => {
	const { toggleModal } = useActions();
	const { reachGoal } = useReachGoal();

	const handleManageFiltersClick = React.useCallback(() => {
		reachGoal(ANALYTICS_GOAL.click_filters_btn);
		toggleModal({
			modalId: MODAL_IDS.TAG_FILTERS,
			isOpen: true,
		});
	}, [toggleModal, reachGoal]);

	return { handleManageFiltersClick };
};

export default useHandleManageFiltersClick;
