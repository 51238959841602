import React from 'react';
import PropTypes from 'prop-types';
import IconLogoBig from '@src/components/Icon/set/IconLogoBig';
import { ANALYTICS_GOAL, useReachGoal } from '@src/libs/hooks/useReachGoal';

import Header from '..';
import css from '../style.module.scss';

const Logo = (props) => <IconLogoBig className={css.plainLogo} {...props} />;

const HeaderCPlain = ({ isDesktopNonSticky }) => {
	const { reachGoal } = useReachGoal();
	const handleClickFav = React.useCallback(() => {
		reachGoal(ANALYTICS_GOAL.click_favorites_btn);
	}, [reachGoal]);

	return (
		<Header
			isLinkOnLogo
			Logo={Logo}
			isPlain
			isDesktopNonSticky={isDesktopNonSticky}
			onClickFav={handleClickFav}
		/>
	);
};

export default HeaderCPlain;

HeaderCPlain.propTypes = {
	isDesktopNonSticky: PropTypes.bool,
};

HeaderCPlain.defaultProps = {
	isDesktopNonSticky: false,
};
