import React from 'react';
import PropTypes from 'prop-types';
import useFreeze from '@src/libs/hooks/useFreeze';

const Freezer = ({ freezeClass, isDefaultFreezed, children }) => {
	const [isFreezed, setIsFreezed] = React.useState(isDefaultFreezed);
	const setFreezed = React.useCallback(() => {
		setIsFreezed(true);
	}, []);
	useFreeze({ freezeClass, cb: setFreezed });
	return isFreezed ? children || null : null;
};

Freezer.propTypes = {
	freezeClass: PropTypes.string,
	isDefaultFreezed: PropTypes.bool,
	children: PropTypes.oneOfType([
		PropTypes.arrayOf(PropTypes.node),
		PropTypes.node,
	]),
};

Freezer.defaultProps = {
	freezeClass: '--scroll-compensator',
	isDefaultFreezed: false,
	children: undefined,
};

export default Freezer;
