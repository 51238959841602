import { createSelector } from 'reselect';
import { uniqBy } from 'lodash-es';
import { FILTER_CAPTIONS, FILTER_CODES } from '../constants';

const getServerList = (store) => store.Filters.loadFilters?.data?.list || [];
const getServerEntities = (store) =>
	store.Filters.loadFilters?.data?.entity || {};

const getFiltersList = createSelector(
	getServerList,
	getServerEntities,
	(list, entities) => {
		return list
			.map((id) => {
				const code = id.split('__')[1];
				const i = entities[code];
				if (!i) return null;

				if (i.key === 'p' || i.key === 'district_id') {
					return {
						id,
						key: i.key,
						checks: {
							title:
								i.key === 'district_id'
									? t('Расположение')
									: FILTER_CAPTIONS[code],
							name: code,
							paramKey: i.key,
							data: uniqBy(
								(i.value || []).map((val) => {
									const t = `${val.key}`;
									return {
										title: val.name || val.value,
										value: t,
									};
								}),
								'value',
							),
						},
					};
				}

				if (code === FILTER_CODES.PRICE) {
					return {
						id,
						key: code,
						slide: i.stat
							? {
									name: code,
									min: i.stat.min || 0,
									max: i.stat.max || 1000000,
									chartData: i.stat.weights.map((v, k) => {
										return {
											name: k,
											qtty: v,
										};
									}),
							  }
							: null,
					};
				}
				return null;
			})
			.filter((i) => !!i);
	},
);

export default getFiltersList;
