import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import getModals from '@src/containers/Session/selectors/getModals';
import { useActions } from '@src/containers/Session/hooks';

import Popup from '..';

const PopupCDefault = ({ modalId, ...restProps }) => {
	const modals = useSelector(getModals);
	const { isOpen, isFullscreen } = modals[modalId] || {};

	const { toggleModal } = useActions();

	const handleClose = useCallback(() => {
		toggleModal({
			modalId,
			isOpen: false,
		});
	}, [modalId, toggleModal]);

	return (
		<Popup
			onClose={handleClose}
			state={isFullscreen ? 'fullscreen' : null}
			isOpen={isOpen}
			{...restProps}
		/>
	);
};

export default PopupCDefault;

PopupCDefault.propTypes = {
	modalId: PropTypes.string.isRequired,
	additionalClass: PropTypes.string,
};

PopupCDefault.defaultProps = {
	additionalClass: undefined,
};
